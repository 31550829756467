import { useTheme } from '@mui/material'
import Box from 'components/commons/Box'
import Stack from 'components/commons/Stack'
import ArrowBackIcon from '@mui/icons-material/ArrowBackIos'
import RefreshIcon from '@mui/icons-material/Refresh'
import Typography from 'components/commons/Typography'

interface ILiteHeaderProps {
  hideBackBtn?: boolean
  showRefreshBtn?: boolean
  onBack?: React.MouseEventHandler
  title?: string
}

export default function LiteHeader({
  hideBackBtn = false,
  onBack,
  title,
  showRefreshBtn = false,
}: ILiteHeaderProps) {
  const theme = useTheme()
  return (
    <Stack
      direction="row"
      alignItems="center"
      sx={{
        borderBottom: `1px solid ${theme.palette.primary.main}`,
        padding: '16px',
        position: 'fixed',
        top: 0,
        zIndex: 1100,
        background: theme.palette.primary.main,
        height: 68,
        width: '100%',
        justifyContent: !hideBackBtn ? 'space-between' : ' end',
        alignItems: 'center',
      }}
    >
      {!hideBackBtn && (
        <ArrowBackIcon
          onClick={onBack}
          sx={{
            color: theme.palette.secondary.main,
          }}
        />
      )}
      {title && (
        <Typography
          variant="h4"
          sx={{
            color: theme.palette.secondary.main,
          }}
        >
          {title}
        </Typography>
      )}
      <Box
        onClick={() => window.location.reload()}
        sx={{
          cursor: 'pointer',
          color: theme.palette.secondary.main,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {showRefreshBtn && <RefreshIcon />}
      </Box>
    </Stack>
  )
}
