/* eslint-disable no-unused-vars */
import '@mui/lab/themeAugmentation'
import { Color } from '@mui/material'
import { createTheme } from '@mui/material/styles'
import React from 'react'
import merge from 'lodash/merge'
import { ThemeKeys } from 'constants/common'

interface ITypeCommon {
  primary: string
  secondary: string
  tertiery: string
}

interface ITypeGhost {
  ghostActive: string
  ghostHover: string
  ghostPressed: string
}

interface ITypeColor
  extends Omit<Color, 50 | 'A100' | 'A200' | 'A400' | 'A700'> {
  500_32?: string
  500_83?: string
}

// Create a theme instance.
const theme = createTheme({
  palette: {
    action: {
      active: '#CB1C4F',
      hover: '#E590A9',
      focus: '#8D1C40',
      disabled: '#F4F6F8',
      ghostActive: 'rgba(203, 28, 79, 0.08)',
      ghostHover: 'rgba(203, 28, 79, 0.04)',
      ghostPressed: 'rgba(203, 28, 79, 0.24)',
    },
    primary: {
      main: '#CB1C4F',
    },
    secondary: {
      main: '#FFFFFF',
    },
    error: {
      main: '#EE2737',
      dark: '#A52331',
      light: '#F7959D',
    },
    success: {
      main: '#139C5B',
      dark: '#147048',
      light: '#8BCEAF',
    },
    info: {
      main: '#4949E7',
      dark: '#383AA5',
      light: '#A6A6F3',
    },
    warning: {
      main: '#F87500',
    },
    background: {
      primary: '#FFFFFF',
      secondary: '#F9FAFB',
      tertiery: '#F4F6F8',
    },
    text: {
      primary: '#212B36',
      secondary: '#637381',
      tertiery: '#919EAB',
    },
    tiptip: {
      100: '#FBEFF3',
      200: '#F7DDE5',
      300: '#E590A9',
      400: '#D4426C',
      500: '#CB1C4F',
      600: '#AC1C48',
      700: '#8D1C40',
      800: '#6F1C39',
      900: '#501C32',
      500_32: 'rgba(203, 28, 79, 0.32)',
      500_83: 'rgba(203, 28, 79, 0.83)',
    },
    icon: {
      primary: '#212B36',
      secondary: '#637381',
      tertiery: '#919EAB',
    },
    border: {
      minimal: 'rgba(145, 158, 171, 0.16)',
      subtitle: 'rgba(145, 158, 171, 0.24)',
      bold: 'rgba(145, 158, 171, 0.32)',
    },
    orange: {
      100: '#FFF5ED',
      200: '#FEEAD9',
      300: '#FCBB82',
      400: '#F98C2A',
      500: '#F87500',
      600: '#D26606',
      700: '#AB570C',
      800: '#854812',
      900: '#5E3818',
    },
    purple: {
      100: '#F2F2FD',
      200: '#E4E4FB',
      300: '#A6A6F3',
      400: '#6868EB',
      500: '#4949E7',
      600: '#4041C6',
      700: '#383AA5',
      800: '#2F3284',
      900: '#262A62',
    },
    blue: {
      100: '#F1F5FD',
      200: '#E0E9FB',
      300: '#9BB7F0',
      400: '#5586E6',
      500: '#326DE1',
      600: '#2D5FC1',
      700: '#2851A1',
      800: '#2F3284',
      900: '#244481',
      500_83: 'rgba(73, 73, 231, 0.83)',
    },
    green: {
      100: '#EFF8F4',
      200: '#DCF0E6',
      300: '#8BCEAF',
      400: '#3BAD77',
      500: '#139C5B',
      600: '#148652',
      700: '#147048',
      800: '#155B3F',
      900: '#154536',
    },
    red: {
      100: '#FEF0F1',
      200: '#FCDFE1',
      300: '#F7959D',
      400: '#F14C59',
      500: '#EE2737',
      600: '#C92534',
      700: '#A52331',
      800: '#80212D',
      900: '#5B202A',
    },
    grey: {
      100: '#F9FAFB',
      200: '#F4F6F8',
      300: '#DFE3E8',
      400: '#C4CDD5',
      500: '#919EAB',
      600: '#637381',
      700: '#454F5B',
      800: '#212B36',
      900: '#161C24',
    },
  },
  typography: {
    fontFamily: ['"Source Sans 3"', 'sans-serif'].join(','),
    button: {
      fontWeight: 700,
      textTransform: 'none',
    },
    normalRegular: {
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '20px !important',
      letterSpacing: 0,
    },
    h1: {
      fontFamily: 'Open Sans',
      fontWeight: '700',
      fontSize: 26,
      lineHeight: '34px',
      letterSpacing: -0.5,
    },
    h1Desk: {
      fontFamily: 'Open Sans',
      fontWeight: '700',
      fontSize: 30,
      lineHeight: '38px',
      letterSpacing: -0.5,
    },
    h2: {
      fontFamily: 'Open Sans',
      fontSize: 24,
      lineHeight: '32px',
      fontWeight: '800',
      letterSpacing: -0.5,
    },
    h2Desk: {
      fontFamily: 'Open Sans',
      fontSize: 28,
      lineHeight: '36px',
      fontWeight: '800',
      letterSpacing: -0.5,
    },
    h3Desk: {
      fontFamily: 'Open Sans',
      fontWeight: '700',
      fontSize: 22,
      lineHeight: '30px',
      letterSpacing: -0.5,
    },
    h3: {
      fontFamily: 'Open Sans',
      fontWeight: '700',
      fontSize: 20,
      lineHeight: '26px',
      letterSpacing: -0.5,
    },
    h4: {
      fontFamily: 'Open Sans',
      fontWeight: '800',
      fontSize: 18,
      lineHeight: '24px',
      letterSpacing: -0.5,
    },
    h4Desk: {
      fontFamily: 'Open Sans',
      fontWeight: '800',
      fontSize: 20,
      lineHeight: '26px',
      letterSpacing: -0.5,
    },
    mediumBold: {
      fontWeight: 'bold',
      fontSize: 16,
      lineHeight: '18px',
      letterSpacing: 0,
    },
    mediumRegular: {
      fontWeight: 'normal',
      fontSize: 16,
      lineHeight: '22px',
      letterSpacing: 0,
    },
    normalBold: {
      fontWeight: 'bold',
      fontSize: 14,
      lineHeight: '20px',
      letterSpacing: 0,
    },
    smallBold: {
      fontWeight: 'bold',
      fontSize: 12,
      lineHeight: '16px',
      letterSpacing: 0,
    },
    smallRegular: {
      fontWeight: 'normal',
      fontSize: 12,
      lineHeight: '16px',
      letterSpacing: 0,
    },
  },
  shape: {
    borderRadius: 12,
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 480,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  colors: {
    gradient1: 'linear-gradient(270deg, #CB1C4F 0%, #FF223E 100%)',
    gradient2: 'linear-gradient(270deg, #F87500 0%, #FFA235 100%)',
    gradientBackground:
      'linear-gradient(180deg, rgba(31, 29, 43, 0) 0%, rgba(31, 29, 43, 0.7) 100%)',
    hoverBackground: 'rgba(20, 18, 32, 0.50)',
    clean: '#F6F6F6',
    eventGreen: '#3FA67A',
    lighterPrimary: '#D93D68',
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundColor: '#FFFFFF',
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          color: '#919EAB',
        },
      },
    },
    MuiBottomNavigation: {
      styleOverrides: {
        root: {
          backgroundColor: '#F9FAFB',
        },
      },
    },
    MuiBottomNavigationAction: {
      styleOverrides: {
        root: {
          color: '#919EAB',
          padding: '4px 8px',
          '& .Mui-selected, &.Mui-selected': {
            color: '#CB1C4F',
            svg: {
              color: '#CB1C4F',
              fill: '#CB1C4F',
            },
          },
          '@media (max-width:353px)': {
            minWidth: '72px',
          },
        },
      },
    },
    MuiInputLabel: {
      defaultProps: {
        shrink: false,
        style: {
          color: '#212B36',
          fontWeight: 'bold',
          fontSize: 14,
          lineHeight: '20px',
          letterSpacing: 0,
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        filled: {
          color: 'white',
        },
        filledSuccess: {
          background: '#139C5B',
          '& .MuiAlert-icon': {
            color: '#8BCEAF',
          },
        },
        filledError: {
          background: '#EE2737',
          '& .MuiAlert-icon': {
            color: '#F7959D',
          },
        },
        filledInfo: {
          background: '#4949E7',
          '& .MuiAlert-icon': {
            color: '#A6A6F3',
          },
        },
        filledWarning: {
          background: '#F87500',
          '& .MuiAlert-icon': {
            color: '#FCBB82',
          },
        },
        outlinedSuccess: {
          '& .MuiAlert-icon': {
            color: '#139C5B',
          },
        },
        outlinedInfo: {
          '& .MuiAlert-icon': {
            color: '#4949E7',
          },
        },
        outlinedWarning: {
          '& .MuiAlert-icon': {
            color: '#F87500',
          },
        },
        outlinedError: {
          '& .MuiAlert-icon': {
            color: '#EE2737',
          },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        root: {
          '& .MuiDialog-paper': {
            background: 'white !important',
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          color: '#212B36',
        },
      },
    },
    MuiLoadingButton: {
      styleOverrides: {
        root: {
          '& .MuiLoadingButton-loadingIndicator': {
            color: '#CB1C4F',
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          height: '32px',
          padding: '8px 16px',
          color: 'white',
          borderRadius: '22px',
        },
      },
      variants: [
        {
          props: { variant: 'filled', color: 'primary' },
          style: {
            background: '#CB1C4F',
          },
        },
        {
          props: { variant: 'filled', color: 'default' },
          style: {
            background: '#F9FAFB',
            border: '1px solid #F4F6F8',
            color: '#637381',
          },
        },
        {
          props: { variant: 'filled', color: 'secondary' },
          style: {
            background: '#F87500',
          },
        },
        {
          props: { variant: 'filled', color: 'info' },
          style: {
            background: '#4949E7',
          },
        },
        {
          props: { variant: 'filled', color: 'success' },
          style: {
            background: '#139C5B',
          },
        },
        {
          props: { variant: 'filled', color: 'error' },
          style: {
            background: '#EE2737',
          },
        },
        {
          props: { variant: 'outlined', color: 'primary' },
          style: {
            borderColor: '#CB1C4F',
            color: '#CB1C4F',
          },
        },
        {
          props: { variant: 'outlined', color: 'default' },
          style: {
            borderColor: '#F4F6F8',
            color: '#637381',
          },
        },
        {
          props: { variant: 'outlined', color: 'secondary' },
          style: {
            borderColor: '#F87500',
            color: '#F87500',
          },
        },
        {
          props: { variant: 'outlined', color: 'info' },
          style: {
            borderColor: '#4949E7',
            color: '#4949E7',
          },
        },
        {
          props: { variant: 'outlined', color: 'success' },
          style: {
            borderColor: '#139C5B',
            color: '#139C5B',
          },
        },
        {
          props: { variant: 'outlined', color: 'error' },
          style: {
            borderColor: '#EE2737',
            color: '#EE2737',
          },
        },
      ],
    },
    MuiIconButton: {
      variants: [
        {
          props: { color: 'primary' },
          style: {
            color: '#212B36',
          },
        },
        {
          props: { color: 'secondary' },
          style: {
            color: '#637381',
          },
        },
        {
          props: { color: 'tertiery' },
          style: {
            color: '#919EAB',
          },
        },
      ],
    },
    MuiSvgIcon: {
      variants: [
        {
          props: { color: 'primary' },
          style: {
            color: '#212B36',
          },
        },
        {
          props: { color: 'secondary' },
          style: {
            color: '#637381',
          },
        },
        {
          props: { color: 'tertiery' },
          style: {
            color: '#919EAB',
          },
        },
      ],
    },
    MuiFormLabel: {
      styleOverrides: {
        asterisk: {
          color: '#637381',
        },
      },
    },
  },
})

declare module '@mui/material/styles' {
  interface Theme {
    colors: {
      gradient1: string
      gradient2: string
      gradientBackground: string
      hoverBackground: string
      clean: string
      eventGreen: string
      lighterPrimary: string
    }
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    colors?: {
      [key: string]: any
    }
  }
}

declare module '@mui/material/styles' {
  interface TypographyVariants {
    normalRegular: React.CSSProperties
    h1Desk: React.CSSProperties
    h1: React.CSSProperties
    h2Desk: React.CSSProperties
    h2: React.CSSProperties
    h3Desk: React.CSSProperties
    h3: React.CSSProperties
    h4Desk: React.CSSProperties
    h4: React.CSSProperties
    mediumBold: React.CSSProperties
    mediumRegular: React.CSSProperties
    normalBold: React.CSSProperties
    smallBold: React.CSSProperties
    smallRegular: React.CSSProperties
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    normalRegular?: React.CSSProperties
    h1Desk?: React.CSSProperties
    h1?: React.CSSProperties
    h2Desk?: React.CSSProperties
    h2?: React.CSSProperties
    h3Desk?: React.CSSProperties
    h3?: React.CSSProperties
    h4Desk?: React.CSSProperties
    h4?: React.CSSProperties
    mediumBold?: React.CSSProperties
    mediumRegular?: React.CSSProperties
    normalBold?: React.CSSProperties
    smallBold?: React.CSSProperties
    smallRegular?: React.CSSProperties
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    [key: string]: true
  }
}

declare module '@mui/material/styles/createPalette' {
  interface TypeBackground extends ITypeCommon {}
  interface TypeText extends ITypeCommon {}
  interface TypeAction extends ITypeGhost {}
}

declare module '@mui/material/styles' {
  interface Palette {
    tiptip: ITypeColor
    icon: ITypeCommon
    border: {
      minimal: string
      subtitle: string
      bold: string
    }
    orange: ITypeColor
    purple: ITypeColor
    blue: ITypeColor
    green: ITypeColor
    red: ITypeColor
  }

  interface PaletteOptions {
    tiptip?: ITypeColor
    icon?: ITypeCommon
    border?: {
      minimal: string
      subtitle: string
      bold: string
    }
    orange?: ITypeColor
    purple?: ITypeColor
    blue?: ITypeColor
    green?: ITypeColor
    red?: ITypeColor
  }
}

declare module '@mui/material/SvgIcon' {
  interface SvgIconPropsColorOverrides {
    tertiery: true
  }
}

declare module '@mui/material/IconButton' {
  interface IconButtonPropsColorOverrides {
    tertiery: true
  }
}

const briTheme = createTheme(
  merge({}, theme, {
    palette: {
      action: {
        active: '#1078CA',
        hover: '#78BDF4',
        focus: '#1078CA',
        disabled: '#F4F6F8',
        ghostActive: 'rgba(16, 120, 202, 0.08)',
        ghostHover: 'rgba(16, 120, 202, 0.04)',
        ghostPressed: 'rgba(16, 120, 202, 0.08)',
      },
      primary: {
        main: '#1078CA',
      },
      secondary: {
        main: '#FFFFFF',
      },
      info: {
        main: '#F2C94C',
        dark: '#383AA5',
        light: '#A6A6F3',
      },
      tiptip: {
        100: '#DDEFFC',
        300: '#BBDEFA',
        500: '#1078CA',
        500_32: 'rgba(28, 95, 203, 0.32)',
      },
    },
    components: {
      MuiLoadingButton: {
        styleOverrides: {
          root: {
            '& .MuiLoadingButton-loadingIndicator': {
              color: '#1078CA',
            },
          },
        },
      },
    },
  }),
)

export const themeMap: Record<ThemeKeys, any> = {
  [ThemeKeys.BRI]: briTheme,
  [ThemeKeys.DEFAULT]: theme,
}

export const getTheme = (themeKey: ThemeKeys) => {
  return themeMap[themeKey] || theme
}

export default theme
