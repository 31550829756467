import { useState, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Skeleton } from '@mui/material'
import { useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import trackEvent from 'trackers'

import Box from 'components/commons/Box'
import ModalDrawer from 'components/domains/User/ModalDrawer'
import FeesPaymentDetail from 'components/domains/Wallet/FeesPaymentDetail'
import PaymentDetailSection from 'containers/domains/Wallet/PaymentDetailSection'
import PaymentMethodList from '../PaymentMethodList'
import SuccessPaymentModal from 'components/domains/Wallet/SuccessPaymentModal'

//store
import { useStoreActions, useStoreState } from 'stores/hooks'

import { sendLog } from 'helpers/log'
import { generateShortUuid } from 'helpers/generate/uuid'
import isEmpty from 'helpers/is-empty'

// types
import {
  ICheckoutData,
  IPaymentStatus,
  IPreCheckoutData,
  PaymentHandlerStrategy,
  PaymentMethodEnum,
} from 'types/payment'
import { usePreCheckout } from 'hooks/domains/Payment/usePreCheckout'
import { useCheckout } from 'hooks/domains/Payment/useCheckout'
import { usePaymentStatus } from 'hooks/domains/Payment/usePaymentStatus'
import PaymentInstructions from '../PaymentInstructions/v2'
import PendingPaymentModal from 'components/domains/Wallet/PendingPaymentModal'
import SuccessTopupCoin from 'components/domains/Wallet/SuccessTopupCoin'
import { useRouter } from 'next/router'
import VoucherInboxModal from '../VoucherInboxModal'
import { IPaymentCheckoutBody } from 'services/payment'
import { usePaymentStore } from 'stores/domains/Payment'
import ErrorPayment, {
  ErrorPaymentCode,
} from 'components/domains/Payment/ErrorPayment/v2'
import { getSelectedPaymentMethodIndex } from 'utils/domains/Payment/paymentUtils'
import PaymentActionsContainer from '../PaymentActionsContainer'

// TO-DO: Move and revisit to src/screens/domains/Payment/Skeleton/index.tsx
function DrawerSkeleton() {
  return (
    <Box>
      <Skeleton
        data-testid="c-payment-skeleton-payment-drawer"
        variant="rectangular"
        height={36}
        width={'100%'}
        sx={{ background: 'lightgrey', borderRadius: '8px' }}
      />
    </Box>
  )
}
export interface IDirectPaymentDrawer {
  title?: string
  trackerData?: any
  paymentHandler: ReturnType<PaymentHandlerStrategy>
  onPaymentClose: Function
  idempotentKey: string
  setIdempotentKey: Function
  modalName?: string
  checkoutTracker?: Function
  selectedProducts?: IPaymentCheckoutBody
  voucherTracker?: any
}

export type PaymentModalType =
  | 'default'
  | 'pending'
  | 'success'
  | 'error'
  | 'instruction'
  | 'voucher'

function DirectPaymentModal({
  title,
  trackerData,
  onPaymentClose = () => {},
  idempotentKey,
  setIdempotentKey,
  modalName = '',
  paymentHandler,
  checkoutTracker = () => {},
  selectedProducts,
  voucherTracker,
}: IDirectPaymentDrawer) {
  const { t } = useTranslation()
  const { query } = useRouter()

  const [activeModal, setActiveModal] =
    useState<PaymentModalType>('default')

  const [isOpenDirectPaymentModal, setIsOpenDirectPaymentModal] =
    useState(true)
  const [selectedPaymentMethod, setSelectedPaymentMethod] =
    useState(0)
  const [isAdditionalFeesOpen, setIsAdditionalFeesOpen] =
    useState(false)
  const [isAppliedVoucher, setIsAppliedVoucher] = useState(false)

  const { setShowTopUpModal, setTopUpCoinStatus } = useStoreActions(
    (actions) => ({
      ...actions.topUp,
      ...actions.eventPersonalQuestions,
    }),
  )
  const { topUpCoinStatus } = useStoreState((states) => ({
    ...states.topUp,
  }))

  const {
    errorPayment,
    isErrorPayment,
    resetErrorPayment,
    preCheckoutWithVoucher,
    setPreCheckoutWithVoucher,
  } = usePaymentStore((state) => ({
    errorPayment: state.errorPayment,
    isErrorPayment: state.isErrorPayment,
    resetErrorPayment: state.resetErrorPayment,
    preCheckoutWithVoucher: state.preCheckoutWithVoucher,
    setPreCheckoutWithVoucher: state.setPreCheckoutWithVoucher,
  }))

  const queryClient = useQueryClient()

  const {
    preCheckoutData: basePreCheckoutData,
    isPreCheckoutPaymentFetching,
    fetchPreCheckout,
    isErrorPreCheckout,
  } = usePreCheckout(paymentHandler)

  const preCheckoutData: IPreCheckoutData = useMemo(() => {
    const isCoinMethod =
      basePreCheckoutData?.result?.coins?.length > 0
    // If checkedVoucherData exists and is not empty, use that; otherwise, fall back to preCheckoutData
    return preCheckoutWithVoucher && !isCoinMethod
      ? {
          ...preCheckoutWithVoucher,
          use_default_admin_fee_title:
            basePreCheckoutData?.result?.use_default_admin_fee_title,
        }
      : basePreCheckoutData?.result
  }, [preCheckoutWithVoucher, basePreCheckoutData])

  const isDisableVoucher = !isEmpty(
    preCheckoutData?.payment_method?.[selectedPaymentMethod]
      ?.disable_voucher_reason,
  )

  const handleCheckoutResponse = (
    checkoutResponse: ICheckoutData,
  ) => {
    if (basePreCheckoutData?.skipPreCheckout) return onPaymentClose()
    if (
      preCheckoutData?.payment_method?.[selectedPaymentMethod]?.price
        ?.total_price === 0
    )
      return window.location.reload()

    if (isEmpty(checkoutResponse?.invoice_url)) {
      if (checkoutResponse?.payment_method_code === 'H2H_BCA_VA')
        setActiveModal('instruction')
      if (checkoutResponse?.payment_method_code === 'TIPTIP_COIN')
        setActiveModal('success')
    } else {
      handleExternalPayment(checkoutResponse)
    }
    return
  }

  const handlePaymentStatusSuccess = (
    paymentStatusResponse: IPaymentStatus,
  ) => {
    if (paymentStatusResponse?.status === 'PENDING')
      setActiveModal('pending')

    if (paymentStatusResponse?.status === 'PAID')
      setActiveModal('success')
  }

  const handlePaymentStatusFailed = (error: AxiosError) => {
    if (error?.response?.data?.code === 'NOT_FOUND')
      queryClient.removeQueries(['payment-instruction'])
  }

  const handleExternalPayment = (checkoutData) => {
    if (window.open(checkoutData?.invoice_url) === null) {
      // TO-DO: Create confirmation pop up
      // setIsConfirmationPopup(true)
    } else {
      setSelectedPaymentMethod(0)
      if (preCheckoutData?.coins?.length) {
        // will return and trigger use effect for checkoutData
        return
      } else {
        handleCloseDirectPaymentModal()
      }
    }
  }

  const handleClearVoucher = () => {
    setIsAppliedVoucher(false)
    setPreCheckoutWithVoucher(undefined)
    setSelectedPaymentMethod(0)
  }

  const topUpCoinHandler = () => {
    setTopUpCoinStatus('start')
    setShowTopUpModal(true)
    setIsOpenDirectPaymentModal(true)
    trackEvent.payment('click_topup', trackerData, {
      modal_name: modalName,
    })
  }

  const handleCheckoutPayment = () => {
    try {
      checkoutTracker(preCheckoutData)
      fetchCheckout()
    } catch (error) {
      sendLog(
        `Error: ${error?.response?.data?.code} - ${error?.response?.data?.message}`,
      )
      if (error?.response?.status !== 500) {
        setIdempotentKey(generateShortUuid())
      }
      if (error?.response?.status === 429) {
        handleCloseDirectPaymentModal()
      }
    }
  }

  const handleCloseDirectPaymentModal = () => {
    onPaymentClose(preCheckoutData)
    queryClient.removeQueries(['check-payment-status'])
    queryClient.removeQueries(['checkout'])
    setIsOpenDirectPaymentModal(false)
    handleClearVoucher()
    setSelectedPaymentMethod(0)
    if (preCheckoutData?.coins?.length) {
      trackEvent.payment('cancel_topup_payment', trackerData, {
        modal_name: 'TOPUP_PAYMENT_METHOD',
        trnx_fee: preCheckoutData?.transaction_fee,
        total_payment:
          preCheckoutData?.payment_method[selectedPaymentMethod]
            ?.total,
        total_topup_coin: trackerData?.totalCoinTopUp,
      })
    }
  }

  const handleActionButtonPendingModalDrawer = () => {
    // When payment method is BCA VA, need to open again payment instruction after pending modal
    if (paymentStatusData?.payment_method_code === 'H2H_BCA_VA') {
      queryClient.removeQueries(['check-payment-status'])
      setActiveModal('instruction')
    } else {
      fetchPaymentStatus()
    }
  }

  const handlePaymentTypeChange = (index) => {
    setSelectedPaymentMethod(index)
    if (
      !isEmpty(preCheckoutData?.payment_method?.[index]?.voucher_code)
    )
      setIsAppliedVoucher(true)
    else setIsAppliedVoucher(false)
  }

  const { checkoutData, isCheckoutPaymentFetching, fetchCheckout } =
    useCheckout({
      paymentHandler,
      checkoutCallback: handleCheckoutResponse,
      paymentMethodCode:
        preCheckoutData?.payment_method?.[selectedPaymentMethod]
          ?.code,
      paymentId: preCheckoutData?.payment_id,
      username: query?.username?.toString() || '',
      invoiceId: preCheckoutData?.payment_info?.invoice_id,
      voucherCode:
        preCheckoutData?.payment_method?.[selectedPaymentMethod]
          ?.voucher_code,
    })

  const { paymentStatusData, fetchPaymentStatus } = usePaymentStatus({
    idempotentKey,
    onCallbackSuccess: handlePaymentStatusSuccess,
    onCallbackFailed: handlePaymentStatusFailed,
    invoiceId: checkoutData?.invoice_id,
    paymentMethodCode: checkoutData?.payment_method_code,
  })

  const getTitleBasedOnState = () => {
    if (activeModal === 'voucher') return 'Voucher Inbox'
    if (activeModal === 'instruction') return 'BCA Virtual Account'
    if (
      activeModal === 'pending' ||
      activeModal === 'success' ||
      activeModal === 'error' ||
      isPreCheckoutPaymentFetching ||
      basePreCheckoutData?.skipPreCheckout
    )
      return ''
    return title
  }

  const getButtonTitlePendingModalDrawer = () => {
    if (paymentStatusData.payment_method_code === 'H2H_BCA_VA')
      return t('back')
    else return t('vaPaymentInstructionWidgetButton')
  }

  const determinePaymentStatus = () => {
    if (!isEmpty(paymentStatusData)) return paymentStatusData
    return {
      ...checkoutData,
      checkout_at: checkoutData?.paid_at,
      payment_method_name: checkoutData?.payment_method_code,
      total: checkoutData?.coin_info?.total_purchase,
      expired_at: checkoutData?.expiry_date,
    } as any
  }

  const renderPaymentButtonLabel = () => {
    // When the selected payment method is TIPTIP COIN,
    // if the precheckout doesn't have a payment method (subscription or live session)
    // or a voucher has been applied for the selected payment method (digital content),
    // the button label "paymentConfirmationBottomSheetButton" will be rendered.
    if (
      preCheckoutData?.coins?.length ||
      !preCheckoutData?.payment_method?.length ||
      isAppliedVoucher
    )
      return t('paymentConfirmationBottomSheetButton')

    return t('key_pay_without_promo')
  }

  useEffect(() => {
    if (topUpCoinStatus === 'finish') {
      fetchPreCheckout()
      setTopUpCoinStatus('')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [topUpCoinStatus])

  useEffect(() => {
    // When checkout top up coin already success, need to trigger check payment status
    if (
      checkoutData &&
      checkoutData?.payment_method_code !== 'H2H_BCA_VA' &&
      preCheckoutData?.coins?.length
    )
      fetchPaymentStatus()
  }, [checkoutData])

  useEffect(() => {
    if (basePreCheckoutData) {
      if (basePreCheckoutData.isCoinPaymentDefault)
        // will select tiptip coin by default
        setSelectedPaymentMethod(PaymentMethodEnum.TIPTIP_COIN)
      else setSelectedPaymentMethod(0)
      if (basePreCheckoutData.skipPreCheckout) fetchCheckout() // will skip pre-checkout flow to select payment method, so direct to checkout flow
    }
  }, [basePreCheckoutData])

  useEffect(() => {
    if (isErrorPayment) setActiveModal('error')
  }, [isErrorPayment])

  useEffect(() => {
    // If checkedVoucherData is not undefined and the voucher code for the selected payment method exists,
    // set the flag to true to indicate that the preCheckout will be applied with the voucher.
    if (
      Boolean(preCheckoutWithVoucher) &&
      !isEmpty(
        preCheckoutWithVoucher?.payment_method[selectedPaymentMethod]
          ?.voucher_code,
      )
    ) {
      setIsAppliedVoucher(true)
    }
  }, [preCheckoutWithVoucher])

  useEffect(() => {
    fetchPreCheckout()
  }, [])

  const isPaymentLoading = useMemo(
    () => isCheckoutPaymentFetching || isPreCheckoutPaymentFetching,
    [isCheckoutPaymentFetching, isPreCheckoutPaymentFetching],
  )

  const isTopUpCoin = preCheckoutData?.coins?.length > 0

  const PreCheckoutSection = () => {
    return (
      <Box>
        {preCheckoutData?.payment_method?.length && (
          <PaymentMethodList
            isPaymentLoading={isPaymentLoading}
            selectedPaymentMethodIndex={selectedPaymentMethod}
            paymentMethodData={preCheckoutData.payment_method}
            handlePaymentTypeChange={handlePaymentTypeChange}
            topUpCoinHandler={topUpCoinHandler}
          />
        )}

        <Box sx={{ marginBottom: '16px' }} />

        {preCheckoutData && (
          <>
            <PaymentDetailSection
              selectedPaymentMethodIndex={getSelectedPaymentMethodIndex(
                preCheckoutData,
                selectedPaymentMethod,
              )}
              isAppliedVoucher={
                isTopUpCoin ? false : isAppliedVoucher
              }
              preCheckoutData={preCheckoutData}
              setIsAdditionalFeesOpen={setIsAdditionalFeesOpen}
              isPageView={false}
            />
            {!basePreCheckoutData?.skipToPayment && (
              <PaymentActionsContainer
                disableVoucherReason={
                  preCheckoutData?.payment_method?.[
                    selectedPaymentMethod
                  ]?.disable_voucher_reason
                }
                handleClearVoucher={(e) => {
                  handleClearVoucher()
                  e.stopPropagation()
                }}
                handleClickVoucherContainer={() => {
                  if (
                    isDisableVoucher ||
                    isPaymentLoading ||
                    isAppliedVoucher
                  )
                    return
                  else setActiveModal('voucher')
                }}
                isAppliedVoucher={
                  isTopUpCoin ? false : isAppliedVoucher
                }
                isDisableVoucherContainer={isDisableVoucher}
                paymentConfirmationLabel={
                  isAppliedVoucher &&
                  preCheckoutData?.payment_method?.[
                    selectedPaymentMethod
                  ].code !== 'TIPTIP_COIN'
                    ? preCheckoutData?.payment_method?.[
                        selectedPaymentMethod
                      ]?.voucher_code.toUpperCase()
                    : t(
                        'paymentConfirmationBottomSheetVoucherDescText',
                      )
                }
                isLoading={isPaymentLoading}
                onClickPaymentButton={handleCheckoutPayment}
                paymentButtonLabel={renderPaymentButtonLabel()}
                preCheckoutData={preCheckoutData}
                selectedPaymentMethodIndex={getSelectedPaymentMethodIndex(
                  preCheckoutData,
                  selectedPaymentMethod,
                )}
                isCoinMethod={
                  selectedPaymentMethod ===
                  PaymentMethodEnum.TIPTIP_COIN
                }
                buttonSubmitPaymentId="b-wallet-checkout-payment-now"
                isPageView={false}
              />
            )}
          </>
        )}
      </Box>
    )
  }

  const renderActiveSection = () => {
    if (
      isPreCheckoutPaymentFetching ||
      basePreCheckoutData?.skipPreCheckout ||
      basePreCheckoutData?.skipToPayment
    )
      return <DrawerSkeleton />

    switch (activeModal) {
      case 'instruction':
        return (
          <PaymentInstructions
            idempotentKey={idempotentKey}
            invoiceId={checkoutData?.invoice_id}
            paymentMethodCode={checkoutData?.payment_method_code}
            onClickActionButton={fetchPaymentStatus}
            isDisableActionButton={isPaymentLoading}
          />
        )
      case 'pending':
        return (
          <PendingPaymentModal
            paymentStatus={paymentStatusData}
            description={
              preCheckoutData?.coins?.length
                ? t('key_please_complete_the_payment_before')
                : t('key_please_complete_the_payment')
            }
            onClickActionButton={handleActionButtonPendingModalDrawer}
            disabled={isPaymentLoading}
            buttonTitle={getButtonTitlePendingModalDrawer()}
          />
        )
      case 'success':
        if (preCheckoutData?.coins?.length) {
          return (
            <SuccessTopupCoin
              topUpCoinStatus={topUpCoinStatus}
              coin_info={paymentStatusData.coin_info}
              handleClose={() => {
                queryClient.removeQueries([
                  `checkout-${paymentHandler.itemType}`,
                ])
                handleCloseDirectPaymentModal()
                fetchPreCheckout()
              }}
            />
          )
        }
        return (
          <SuccessPaymentModal
            paymentStatus={determinePaymentStatus()}
            onClickActionButton={handleCloseDirectPaymentModal}
            buttonTitle={t('transactionVaStatusWidgetBtn')}
            disabled={isPaymentLoading}
            description={t('key_thankyou_your_purchase_content')}
            renderTotalPriceWithCoin={
              checkoutData?.payment_method_code === 'TIPTIP_COIN'
            }
          />
        )
      case 'voucher':
        return (
          <VoucherInboxModal
            handleClearVoucher={handleClearVoucher}
            voucherTracker={voucherTracker}
            onClose={() => setActiveModal('default')}
            isOpenVoucherInboxModal={activeModal === 'voucher'}
            selectedProducts={selectedProducts}
            selectedPaymentMethod={
              preCheckoutData?.payment_method?.[selectedPaymentMethod]
                ?.code
            }
          />
        )

      case 'error':
        return (
          <ErrorPayment
            onCloseErrorPayment={() => {
              setActiveModal('default')
              resetErrorPayment()
              setSelectedPaymentMethod(0)
            }}
            onRetryHandler={() => {
              setActiveModal('default')
              resetErrorPayment()
              if (isErrorPreCheckout) fetchPreCheckout()
            }}
            onContinueTransaction={resetErrorPayment}
            isPaymentLoading={isPaymentLoading}
            errorData={{
              code: errorPayment?.response?.data
                .code as ErrorPaymentCode,
              message: errorPayment?.message,
              status: errorPayment?.response?.status,
              data: errorPayment?.response?.data,
              requestId: errorPayment?.response?.data?.requestId,
            }}
            setShowTopUpModal={() => {
              setTopUpCoinStatus('start')
              setShowTopUpModal(true)
            }}
          />
        )

      default:
        return <PreCheckoutSection />
    }
  }

  return (
    <>
      {preCheckoutData?.payment_method?.[selectedPaymentMethod]?.price
        ?.additional_fee && (
        <FeesPaymentDetail
          paymentMethod={
            preCheckoutData?.payment_method[selectedPaymentMethod]
          }
          isAdditionalFeesOpen={isAdditionalFeesOpen}
          setIsAdditionalFeesOpen={setIsAdditionalFeesOpen}
        />
      )}
      <ModalDrawer
        title={getTitleBasedOnState()}
        isOpen={isOpenDirectPaymentModal}
        onClose={handleCloseDirectPaymentModal}
        sxDrawerContainer={{ padding: '16px 0px' }}
        renderTitle
        testId="c-wallet-direct-payment-modal"
        hideCloseButton={isPreCheckoutPaymentFetching}
      >
        {renderActiveSection()}
      </ModalDrawer>
    </>
  )
}

export default DirectPaymentModal
